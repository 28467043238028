<template>
    <div class="card w-10" style="max-width: 800px; margin: auto">
        <div class="close-div">
            <font-awesome-icon class="close" icon="window-close" @click="confirmarSaida()" />
        </div>
        <div class="formgrid grid p-fluid mt-4 justify-content-center">
            <div class="field col-10 md:col-12 lg:col-12 xl:col-12">
                <h1>Cadastrar Participante</h1>
                <h4>Informe o CPF do participante</h4>
                <InputMask
                    v-if="!ocultarCPF"
                    v-model="cpf"
                    mask="999.999.999-99"
                    :unmask="true"
                    @keypress="v$.cpf.$touch()"
                    class="text-4xl"
                    ref="cpf"
                    :disabled="participante.cpf"
                />
                <InputMask
                    v-else
                    type="password"
                    v-model="cpf"
                    mask="999.999.999-99"
                    :unmask="true"
                    @keypress="v$.cpf.$touch()"
                    class="text-4xl"
                    ref="cpf"
                    :disabled="participante.cpf"
                />
                <div>
                    <Checkbox v-model="ocultarCPF" :binary="true" style="margin-top: 1em" />
                    <label for=""> Ocultar CPF</label>
                </div>
                <small class="p-error" v-if="(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid) && v$.cpf.$error">O CPF é obrigatório</small>
                <small class="p-error" v-if="v$.cpf.cpfValido.$invalid && !(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid)">CPF inválido</small>
            </div>
        </div>
        <div class="formgrid grid p-fluid mt-4 justify-content-center">
            <div class="field col-10 md:col-12 lg:col-12 xl:col-12">
                <h4>Informe o nome do participante</h4>
                <InputText v-model="nome" @input="v$.nome.$touch()" class="text-4xl" ref="nome" />
                <small class="p-error" v-if="v$.nome.$error">O nome é obrigatório</small>
            </div>
        </div>
        <div class="formgrid grid p-fluid mt-4 justify-content-center">
            <div class="field col-10 md:col-12 lg:col-12 xl:col-12">
                <h4>Informe o e-mail do participante</h4>
                <InputText v-model="email" @input="v$.email.$touch()" class="text-4xl" />
                <small class="p-error" v-if="v$.email.$error">O e-mail é obrigatório</small>
            </div>
        </div>
        <div class="formgrid grid p-fluid mt-4 justify-content-center">
            <div class="field col-10 md:col-12 lg:col-12 xl:col-12">
                <h4>Informe o telefone do participante</h4>
                <InputMask v-model="telefone" mask="(99)99999-9999" :unmask="true" @keypress="v$.telefone.$touch()" class="text-4xl" />
                <small class="p-error" v-if="v$.telefone.$error">O telefone é obrigatório</small>
            </div>
        </div>
        <div v-if="evento.aplicarRfid" class="formgrid grid p-fluid mt-4 justify-content-center">
            <div class="field col-10 md:col-12 lg:col-12 xl:col-12">
                <h4>Informe o RFID do participante</h4>
                <InputText v-model="rfid" class="text-4xl" />
            </div>
        </div>
        <div class="formgrid grid p-fluid mt-4 justify-content-center">
            <div class="field field-checkbox col-12 md:col-12">
                <Checkbox v-model="consentimento" :binary="true" id="consentimento" />
                <label for="consentimento" class="p-error text-2xl"
                    >ATENÇÃO: Você autoriza este aplicativo capturar informações para que você compartilhe seus dados com o SEBRAE</label
                >
            </div>
        </div>
        <div class="flex justify-content-center align-items-center">
            <Button
                icon="pi pi-qrcode"
                @click="gerarQrCode()"
                label="Gerar QRCode"
                title="Clique para gerar o QRCode do participante"
                :disabled="v$.$invalid || !consentimento"
                class="mt-4 text-3xl mr-4"
            />
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
    props: {
        participante: {
            type: Object,
            required: true,
        },
        evento: {
            type: Object,
            required: true,
        },
    },

    emits: ['gerar-qrcode', 'reiniciar'],

    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            cpf: null,
            nome: null,
            email: null,
            telefone: null,
            consentimento: false,
            ocultarCPF: true,
            rfid: null,
        };
    },

    validations() {
        return {
            cpf: {
                required,
                minLength: minLength(11),
                cpfValido: () => {
                    return this.$validarCPF(this.cpf);
                },
            },
            nome: {
                required,
            },
            email: {
                required,
                emailValido: () => {
                    var re = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
                    return re.test(this.email?.trim());
                },
            },
            telefone: {
                required,
            },
        };
    },

    methods: {
        gerarQrCode() {
            let participante = {
                cpf: this.cpf,
                nome: this.nome,
                email: this.email,
                telefone: this.telefone,
            };
            if (this.evento.aplicarRfid) {
                participante.rfid = this.rfid;
            }
            this.$emit('gerar-qrcode', participante);
        },

        confirmarSaida() {
            this.$confirm.require({
                message: `Tem certeza que deseja sair?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$emit('reiniciar');
                },
            });
        },
    },

    mounted() {
        this.cpf = this.participante.cpf;
        this.nome = this.participante.nome;
        this.email = this.participante.email;
        this.telefone = this.participante.telefone;
        this.rfid = this.participante.rfid;
        this.$refs.nome.$el.focus();
    },
};
</script>

<style scoped>
.close-div {
    display: flex;
    justify-content: flex-end;
}
.close {
    cursor: pointer;
    height: 30px;
    width: 30px;
}
</style>
