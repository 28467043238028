<template>
    <div>
        <div class="flex justify-content-center">
            <div class="mt-4">
                <img class="image" alt="user header" src="layout/images/sebrae-transparente.png" style="width: 50%" />
            </div>
        </div>
        <div class="flex justify-content-center">
            <div class="mt-4">
                <h3>{{ evento.nome }}</h3>
            </div>
        </div>
        <div class="formgrid grid p-fluid justify-content-center mt-4" v-if="etapa == 'pesquisa'">
            <div class="field col-10 md:col-6 lg:col-4 xl:col-4">
                <h4>Informe o CPF do participante</h4>
                <InputMask
                    v-model="cpf"
                    mask="999.999.999-99"
                    class="text-6xl"
                    ref="cpf"
                    inputmode="numeric"
                    :unmask="true"
                    :autoClear="false"
                    @click="moveCursorToStart"
                    @keypress="v$.cpf.$touch()"
                    @keydown.enter="pesquisarParticipante()"
                />
                <small class="p-error" v-if="(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid) && v$.cpf.$error">O CPF é obrigatório</small>
                <small class="p-error" v-if="v$.cpf.cpfValido.$invalid && !(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid)">CPF inválido</small>
            </div>
        </div>
        <div class="flex justify-content-center" v-if="etapa == 'pesquisa'">
            <Button
                icon="pi pi-search"
                @click="pesquisarParticipante()"
                label="Buscar"
                title="Clique para verificar o participante"
                :disabled="v$.cpf.$invalid"
                class="mt-4 text-3xl"
            />
        </div>
        <div v-if="etapa == 'qrcode' && participante">
            <div class="text-center card" style="width: 80%; max-width: 400px; margin: auto">
                <div class="close-div">
                    <font-awesome-icon class="close" icon="window-close" @click="confirmarSaida()" />
                </div>
                <img width="300" height="300" :src="`data:image/PNG;base64,${participante.qrCode}`" alt="QrCode" />
                <hr />
                <div class="flex justify-content-center align-items-center">
                    <div style="margin-bottom: 8px">
                        <font-awesome-icon icon="user" size="lg" />
                    </div>
                    <div style="margin: auto">
                        <h5>{{ participante.nome }}</h5>
                    </div>
                </div>
                <div class="flex align-items-center">
                    <div style="margin-bottom: 8px">
                        <font-awesome-icon icon="envelope" size="lg" />
                    </div>
                    <div style="margin: auto">
                        <h5>{{ participante.email }}</h5>
                    </div>
                </div>
                <div class="flex align-items-center">
                    <div style="margin-bottom: 8px">
                        <font-awesome-icon icon="phone" size="lg" />
                    </div>
                    <div style="margin: auto">
                        <h5>{{ participante.telefone }}</h5>
                    </div>
                </div>

                <div class="flex justify-content-center align-items-center">
                    <Button icon="pi pi-print" @click="emitirEtiqueta()" label="Imprimir Local" title="Clique para emitir a etiqueta" class="mt-4 text-3xl" />
                    <br />
                </div>
            </div>
        </div>
        <div v-if="etapa == 'formulario' && participante">
            <credenciamento-form :participante="participante" :evento="evento" @gerar-qrcode="gerarQrCode($event)" @reiniciar="reiniciar()" />
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import Servicos from './services.js';
import CredenciamentoForm from './CredenciamentoForm.vue';

const CHECK_IN_ORIGEM = {
    ANDROID: 0,
    IOS: 1,
    API: 2,
};

export default {
    components: {
        CredenciamentoForm,
    },

    props: {
        evento: {
            type: Object,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            cpf: null,
            etapa: 'pesquisa',
            participante: null,
        };
    },

    validations() {
        return {
            cpf: {
                required,
                minLength: minLength(11),
                cpfValido: () => {
                    return this.$validarCPF(this.cpf);
                },
            },
        };
    },

    beforeCreate() {
        this.$store.dispatch('verificarVersao');
    },

    methods: {
        pesquisarParticipante() {
            this.$store.dispatch('addRequest');
            Servicos.pesquisarParticipante(this.cpf, this.$route.query.evento)
                .then((response) => {
                    if (response?.success) {
                        this.participante = response.data;
                        if (this.participante.rfid) {
                            this.etapa = 'qrcode';
                        } else {
                            this.etapa = 'formulario';
                        }
                    } else {
                        this.participante = { cpf: this.cpf };
                        this.etapa = 'formulario';
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                    if (this.evento.impressaoAutomatica) {
                        this.emitirEtiqueta();
                    }
                });
        },

        reiniciar() {
            this.etapa = 'pesquisa';
            this.participante = null;
            this.cpf = null;
            this.v$.cpf.$reset();
        },

        emitirEtiqueta() {
            this.$store.dispatch('addRequest');
            Servicos.emitirEtiqueta(this.participante)
                .then((response) => {
                    if (response?.success) {
                        this.printPDF(response.data, `${this.nome}-Qrcode.pdf`, 'application/pdf');
                        // this.$download(response.data, `Credenciamento_${participante.nome}.pdf`);
                        if (this.evento.checkinAutomatico) {
                            this.fazerCheckinAutomatico();
                        } else {
                            this.reiniciar();
                        }
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Anexo',
                            detail: 'Erro ao emitir etiqueta',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        printPDF(data, filename, mime) {
            var blob = new Blob([data], { type: mime || 'application/octet-stream' });
            var blobURL = window.URL.createObjectURL(blob);

            var printIframe = document.createElement('iframe');
            document.body.appendChild(printIframe);

            printIframe.style.position = 'absolute';
            printIframe.style.top = '0';
            printIframe.style.left = '-1000px';

            printIframe.onload = function () {
                setTimeout(function () {
                    printIframe.focus();
                    printIframe.contentWindow.print();
                }, 100);
            };

            printIframe.src = blobURL;
        },

        gerarQrCode(participante) {
            this.$store.dispatch('addRequest');
            Servicos.gerarQrCode(participante).then((response) => {
                if (response?.success) {
                    this.etapa = 'qrcode';
                    this.participante = response.data;
                } else {
                    this.etapa = 'formulario';
                }
                this.$store.dispatch('removeRequest');
            });
        },

        fazerCheckinAutomatico() {
            this.$store.dispatch('addRequest');
            const novoCheckin = {
                eventoId: this.$route.query.evento,
                qrCode: this.participante.vcard,
                dataLeitura: this.$moment(new Date()).local().format('YYYY-MM-DDTHH:mm:ss'),
                origem: CHECK_IN_ORIGEM.API,
            };
            Servicos.fazerCheckinAutomatico(novoCheckin)
                .then((response) => {
                    if (response?.success) {
                        this.reiniciar();
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkin',
                            detail: 'Erro ao fazer Checkin automático',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        moveCursorToStart(event) {
            event.target.setSelectionRange(0, 0);
        },

        confirmarSaida() {
            this.$confirm.require({
                message: `Tem certeza que deseja sair?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.reiniciar();
                },
            });
        },
    },

    mounted() {
        this.$store.dispatch('carregarConfigApp');
        this.$refs.cpf.$el.focus();
    },

    updated() {
        if (this.etapa == 'pesquisa') {
            this.$refs.cpf.$el.focus();
        }
    },
};
</script>

<style scoped>
.image {
    display: flex;
    margin: auto;
}
.close-div {
    display: flex;
    justify-content: flex-end;
}
.close {
    cursor: pointer;
    height: 30px;
    width: 30px;
}
</style>
