<template>
    <div>
        <div class="overflow-hidden" v-if="imagem">
            <div class="bg-cover bg-center w-full h-screen" :style="{ backgroundImage: `url(data:image/PNG;base64,${imagem})` }">
                <CredenciamentoCpf v-if="evento" :evento="evento"></CredenciamentoCpf>
            </div>
        </div>
        <div v-if="!imagem">
            <CredenciamentoCpf v-if="evento" :evento="evento"></CredenciamentoCpf>
        </div>
    </div>
</template>

<script>
import CredenciamentoCpf from './CredenciamentoCpf.vue';
import Services from './services.js';

export default {
    components: {
        CredenciamentoCpf,
    },

    data: () => ({
        imagem: null,
        evento: {},
    }),

    methods: {
        obterImagemCredenciamento() {
            this.$store.dispatch('addRequest');
            Services.obterConfigEvento(this.$route.query.evento)
                .then((response) => {
                    if (response?.success) {
                        this.imagem = response.data.imagem;
                        this.evento = {
                            nome: response.data.nome,
                            impressaoAutomatica: response.data.impressaoAutomatica,
                            checkinAutomatico: response.data.checkinAutomatico,
                            aplicarRfid: response.data.aplicarRfid,
                        };
                    } else {
                        this.imagem = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },

    mounted() {
        if (this.$route.query.evento) {
            this.obterImagemCredenciamento();
        }
    },
};
</script>
